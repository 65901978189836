export const sliderSetting = {
    slidesperview :1,
    spacebetween: 50,
    breakpoints: {
        480:{
            slidesperview:1
        },
        600:{
            slidesperview:2
        },
        750:{
            slidesperview:3
        },
        1100:{
            slidesperview:4
        }
    }
}